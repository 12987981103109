import React, { useEffect, useRef, useState } from "react";
import { Navbar, Nav, Button, Container, Col, Row } from "react-bootstrap";
import logo from "../assets/img/logo_rond.png";
import background from "../assets/img/background.png";
import statueRight from "../assets/img/statueRight.png";
import statueLeft from "../assets/img/statueLeft.png";

import "./Home.css";

import portrait_duo from "../assets/img/portrait_duo.png";

const Home = () => {
  // Utilisation de refs pour chaque image
  const statueRef1 = useRef(null);
  const statueRef2 = useRef(null);
  const statueRef3 = useRef(null);
  const statueRef4 = useRef(null);

  const [visibleImages, setVisibleImages] = useState({
    image1: false,
    image2: false,
    image3: false,
    image4: false,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Utilisation des refs pour identifier quelle image est visible
          if (entry.target === statueRef1.current) {
            setVisibleImages((prevState) => ({
              ...prevState,
              image1: entry.isIntersecting,
            }));
          } else if (entry.target === statueRef2.current) {
            setVisibleImages((prevState) => ({
              ...prevState,
              image2: entry.isIntersecting,
            }));
          } else if (entry.target === statueRef3.current) {
            setVisibleImages((prevState) => ({
              ...prevState,
              image3: entry.isIntersecting,
            }));
          } else if (entry.target === statueRef4.current) {
            setVisibleImages((prevState) => ({
              ...prevState,
              image4: entry.isIntersecting,
            }));
          }
        });
      },
      {
        threshold: 0.1, // Déclenchement à 10% visible
      }
    );

    // Observer les images individuellement
    if (statueRef1.current) observer.observe(statueRef1.current);
    if (statueRef2.current) observer.observe(statueRef2.current);
    if (statueRef3.current) observer.observe(statueRef3.current);
    if (statueRef4.current) observer.observe(statueRef4.current);

    // Nettoyage
    return () => {
      if (statueRef1.current) observer.unobserve(statueRef1.current);
      if (statueRef2.current) observer.unobserve(statueRef2.current);
      if (statueRef3.current) observer.unobserve(statueRef3.current);
      if (statueRef4.current) observer.unobserve(statueRef4.current);
    };
  }, []);

  // Retour view
  return (
    <div>
      <>
        <div>
          {/* Background + titre + portrait */}
          <div className="background-container">
            <img src={background} alt="Background" />
            <div className="overlay"></div>
            <Row className="block-title-home">
              <div className="title-home pe-5 ps-5 pt-2 pb-2">
                <h1 className="rowdies-bold">
                  Crois en toi parce que tout est possible.
                </h1>
                <p className="montserrat-light">
                  Cette émission aborde les thématiques de la confiance en soi.
                  <br />
                  Découvrez des techniques pour booster votre quotidien.
                  <br /> Toutes les semaines, retrouvez Frank & Christophe pour
                  une émission sur votre confiance en vous !
                </p>
              </div>

              <div className="container-btn-discover pe-5 ps-5 pt-2 pb-2">
                {/* <Col></Col> */}

                <Button className=" btn-discover  ">Découvrir IPIA</Button>
              </div>
            </Row>
            {/* Portrait + texte */}
            <div className="portrait-section" id="emission">
              <div className="text-image-container">
                <Col md={4}>
                  <div className="image-side">
                    <img src={portrait_duo} alt="Description" />
                  </div>
                </Col>
                <Col md={8}>
                  <div className="text-portrait">
                    <h2 className="text-portrait-title">
                      EMISSION DE LA CONFIANCE
                    </h2>

                    <p>
                      La confiance en soi est une qualité essentielle qui
                      influence de nombreux aspects de la vie, que ce soit dans
                      les relations personnelles, le travail, ou les projets
                      personnels. Avoir une bonne estime de soi permet de se
                      sentir capable de relever des défis, de prendre des
                      initiatives, et de persévérer face aux obstacles.
                      Cependant, cette confiance ne naît pas toujours
                      naturellement. Elle peut être le fruit d’un travail
                      conscient sur soi-même, incluant la reconnaissance de ses
                      forces, l'acceptation de ses faiblesses, et le
                      développement d’une attitude positive. La confiance en soi
                      se construit également à travers l’expérience, chaque
                      réussite, même petite, contribuant à renforcer la
                      conviction que l’on est capable de réussir.
                    </p>
                  </div>
                </Col>
              </div>
            </div>
          </div>

          {/* Sections de texte + images */}
          <div className="sections" id="pilliers">
            {/* Section 1 */}
            <Row className="section-white">
              {/* Texte avec titre */}
              <Col md={6}>
                <h2 className="rowdies-bold pb-5 m-5">CONFIANCE EN SOI</h2>
                <p className="ps-5">
                  La <b>confiance en soi</b> se réfère à la croyance en ses propres
                  capacités pour accomplir des tâches et surmonter des défis.
                  Elle est étroitement liée à notre capacité d'action et de
                  performance dans diverses situations. La confiance en soi est
                  construite à travers des expériences réussies et la
                  reconnaissance de nos compétences. Par exemple, commencer un
                  nouveau projet avec assurance démontre une confiance en ses
                  capacités à réussir
                </p>
              </Col>
              {/* Image */}
              <Col md={4} className="">
                <div className="image-container">
                  <img
                    ref={statueRef1}
                    src={statueRight}
                    alt="img"
                    // className={`statue img_section z-1`}
                    className={`statue-image statue-right ${
                      visibleImages.image1 ? "visible" : ""
                    }`}
                    // style={{
                    //   transform: `rotate(${transform1.rotation}deg) scale(${transform1.scale}) translateY(${transform1.translateY}px)`,
                    //   transition: 'transform 0.2s ease-in-out',
                    // }}
                  />
                </div>
              </Col>
            </Row>
            {/* Section 2 */}
            <Row className="section-colored z-2">
              {/* Image */}
              <Col md={4} className="">
                <div className="image-container">
                  <img
                    ref={statueRef2}
                    src={statueLeft}
                    alt="img"
                    // className={`statue img_section z-3 `}
                    className={`statue-image statue-left ${
                      visibleImages.image2 ? "visible" : ""
                    }`}
                    // style={{
                    //   transform: `rotate(${transform2.rotation}deg) scale(${transform2.scale}) translateY(${transform2.translateY}px)`,
                    //   transition: 'transform 0.2s ease-in-out',
                    // }}
                  />
                </div>
              </Col>
              {/* Texte avec titre */}
              <Col md={6}>
                <h2 className="rowdies-bold pb-5 m-5">AMOUR DE SOI</h2>
                <p className="ps-5">
                L’<b>amour de soi</b> est la capacité à s'accepter pleinement et inconditionnellement, y compris ses défauts et ses faiblesses, sans jugement. Il s'agit de se traiter avec bienveillance et de répondre à ses propres besoins émotionnels et physiques. L'amour de soi implique de reconnaître sa valeur intrinsèque, indépendamment des réussites ou des échecs 
                </p>
              </Col>
            </Row>
            {/* Section 3 */}
            <Row className="section-white section3" style={{ zIndex: 4 }}>
              {/* Texte avec titre */}
              <Col md={6}>
                <h2 className="rowdies-bold pb-5 m-5">IMAGE DE SOI</h2>
                <p className="ps-5">
                  L'<b>image de soi</b> est la perception que l’on a de soi-même, tant sur le plan physique que psychologique. Elle englobe la manière dont nous voyons nos caractéristiques, compétences et apparence. Une image de soi positive est cruciale pour un bon développement personnel et social, tandis qu'une image de soi négative peut conduire à des problèmes de santé mentale comme la dépression et l'anxiété 
                </p>
              </Col>
              {/* Image */}
              <Col md={4}>
                <div className="image-container">
                  <img
                    ref={statueRef3}
                    src={statueRight}
                    alt="img"
                    className={`statue-image statue-right ${
                      visibleImages.image3 ? "visible" : ""
                    }`}
                  />
                </div>
              </Col>
            </Row>
            {/* Section 4 */}
            <Row className="section-colored section 4" style={{ zIndex: 6 }}>
              {/* Image */}
              <Col md={4}>
                <div className="image-container">
                  <img
                    ref={statueRef4}
                    src={statueLeft}
                    alt="img"
                    className={`statue-image statue-left ${
                      visibleImages.image4 ? "visible" : ""
                    }`}
                  />
                </div>
              </Col>
              {/* Texte avec titre */}
              <Col md={6}>
                <h2 className="rowdies-bold pb-5 m-5">ESTIME DE SOI</h2>
                <p>
                L’<b>estime de soi</b> est la valeur que l'on s’accorde en tant que personne. Elle résulte de notre évaluation globale de nos qualités et de nos réussites. L'estime de soi est influencée par des facteurs tels que les expériences de vie, le soutien social, et l'amour de soi. Une estime de soi élevée est associée à un bien-être général et à une meilleure capacité à fixer et atteindre des objectifs personnels
                </p>
              </Col>
            </Row>
            <section className="team-section" style={{ zIndex: 8 }}>
              <h2>Autre Section</h2>
              <p>Cette section est également sous l'image de fond.</p>
              <br />
              <br />
              <br />
              <br />
            </section>
          </div>
        </div>
      </>
      {/* Navbar */}
      <Row className="navbarhome justify-content-center navbar-row">
        {/* <Col xs={0} md={2} /> */}
        <Col xs={12} md={10}>
          <Navbar
            bg="dark"
            variant="dark"
            expand="lg"
            className="navbar-custom"
          >
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto mt-3">
                <Col md={2}>
                  <Nav.Link className="nav-link" href="#home">ACCUEIL</Nav.Link>
                </Col>
                <Col md={2}>
                <Nav.Link href="#emission">EMISSION</Nav.Link>
                </Col>
                <Col md={2}>
                  <Nav.Link href="#pilliers">PILIERS</Nav.Link>
                </Col>
                <Navbar.Brand href="#home" className="mx-auto logo-custom">
                  <img src={logo} />
                </Navbar.Brand>
                <Col md={2}>
                  <Nav.Link href="#team" className="ps-5">
                    EQUIPE
                  </Nav.Link>
                </Col>
                <Col md={3}>
                  <Nav.Link href="#communaute" className="ps-5">
                    COMMUNAUTÉ
                  </Nav.Link>
                </Col>
                <Col md={3}>
                  <div className="ps-5">
                    <Button className="nav-button-live ">EN LIVE</Button>
                  </div>
                </Col>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
        {/* <Col xs={0} md={2} /> */}
      </Row>
      <Row>
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          toto
          <br />
        </div>
      </Row>
    </div>
  );
};

export default Home;
