
const Footer = () => {
    
    return(
        <div>

            FOOTER
        </div>
    )
}

export default Footer