import { BrowserRouter } from "react-router-dom";

import ReactPlayer from "react-player";
import TestYoutube from "./view/testYoutube";

import "./App.css";


import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

// Font
<link rel="preconnect" href="https://fonts.googleapis.com"></link>;
<link href="https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap" rel="stylesheet"></link>;

const App = () => {
  return (
    <BrowserRouter>
      <div>
      <Home/>
       
        <Footer/>
      </div>
      


    </BrowserRouter>
  )
}

export default App;
