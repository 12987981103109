import React from 'react';
import ReactPlayer from "react-player";

const testYoutube = () => {
  return (
    
    <div className="tt">
      <p>efveôpfje</p>
      <ReactPlayer url="<https://www.youtube.com/watch?v=jfKfPfyJRdk>" />
    </div>
  );
};

export default testYoutube;
